import * as React from "react";
import "./hero.css";
import headshot from "../assets/headshot.png";

export const Hero: React.FC<{}> = () => {
    return (
        <div className={"hero"}>
            <div className={"hero-text"}>
                <div className="title">
                    {"Hi, I'm Arnav"}
                    <Emoji className="emoji" symbol={0x1F44B} label={"Hand-waving emoji"}/>
                </div>
                <div className="subtitle">
                {"I'm a privacy engineer. I build software for data protection, data governance, and responsible use of AI/ML – and I write about privacy and policy, too"}
                </div>
            </div>
            <div className="headshot">
                <img  className="image" src={headshot} alt="Arnav" />
            </div>

        </div>
    )
}


interface EmojiProps {
    className?: string;
    symbol: number;
    label: string;
}

const Emoji = React.memo(({className, symbol, label}: EmojiProps) => {
    return (
        <span className={className} role="img" aria-label={label}>
            {String.fromCodePoint(symbol)}
        </span>
        );
});