import React from 'react';
import './App.css';
import data from './assets/articles.json';
import { Page } from './components/page';
import { Header } from './components/header';
import { Hero } from './components/hero';
import { ArticleSection, ArticleSectionProps } from './components/articleSection';
import { Footer } from './components/footer';

const App: React.FC = () => {
  return (
    <div className="app">
      <Header />
      <Page>
        <Hero />
        {renderArticles()}
        <Footer />
      </Page>
    </div>
  );
}

function renderArticles() {
  const articleSections = data as ArticleSectionProps[];
  const divs = articleSections.map((articleSection, idx) => 
    <ArticleSection title={articleSection.title} description={articleSection.description} articles={articleSection.articles} />
  )

  return (<>{divs}</>);
}

export default App;