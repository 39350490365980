import * as React from 'react';
import "./page.css";

export interface PageProps {
    children?: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({ children }) => {
    return (
        <div className={"page"}>{children}</div>
    )
}