import * as React from "react";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import "./header.css";

export const Header = () => {
    return (
        <header className="header-container">
            <div className="header-title"> {"Arnav Jagasia"} </div>
            <div className="header-actions">
                <a href="https://www.twitter.com/arnavjagasia" rel="noreferrer noopener" target="_blank">
                    <TwitterIcon className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/in/arnavjagasia" rel="noreferrer noopener" target="_blank">
                    <LinkedInIcon className="social-icon"/>
                </a>
            </div>
        </header>
    )
}
