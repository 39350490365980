import * as React from "react";
import "./articleSection.css";

export interface ArticleSectionProps {
    title: string;
    description: string;
    articles: ArticleProps[];
}

export const ArticleSection: React.FC<ArticleSectionProps> = ({ title, description, articles}) => {
    return (
        <div className="section-container">
            <div className="section-details">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
            </div>
            <ul className="article-list">
                {articles.map(article => <li><Article {...article} /></li>)}
            </ul>
        </div>
    )
}


export interface ArticleProps {
    title: string;
    publisher: string;
    date: string;
    link: string;
}

const Article: React.FC<ArticleProps> = ({title, publisher, date, link}) => {
    return (
        <a href={link} rel="noreferrer noopener" target="_blank">{title}</a>
    );
}